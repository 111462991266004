
  import { defineComponent, computed, watch } from "vue"
  import {useModelWrapper} from "@/utils/modelWrapper"

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    components: {
      // load at runtime to avoid circular dependency https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
      FormElement: () => import('./FormElement.vue')
    },
    props: {
      schema: {
        type: Object,
        required: true
      },
      uiSchema: {
        type: Object,
        required: true
      },
      title: {
        type: String
      },
      widget: {
        type: [String, Function]
      },
      parentScope: {
        type: String,
        required: true
      }
    },
    setup(props, {emit}) {
      // Can't use useModelWrapper here because we update the keys of the object only
      const propertyRequired = (schema: any, key: string) => {
        return schema.required && schema.required.includes(key)
      }

      return {
        propertyRequired
      }
    }
  })
